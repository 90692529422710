/* eslint-disable no-underscore-dangle */
/* eslint-disable react/prop-types */

import React, { useState, useEffect } from "react";
import { graphql } from "gatsby";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { v4 as uuidv4 } from "uuid";
import { useBreakpoint } from "gatsby-plugin-breakpoints";
import {
  Button,
  Grid,
  Go,
  SwiperCarousel,
  ProductCard,
  PortableText
} from "~components";
import { MEDIA_QUERIES } from "~utils/helpers";

const Wrapper = styled.div`
  position: relative;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  padding: 3rem 0;
  color: black;

  ${MEDIA_QUERIES.desktop} {
    padding: 5rem 0;
  }
`;

const ButtonContainer = styled.div`
  grid-column: 1 / -1;
  position: relative;
  padding: 2rem 1rem 0;
  display: flex;
  align-items: center;
  justify-content: center;

  ${MEDIA_QUERIES.desktop} {
    padding: 2rem 0;
  }
`;

const ProductCarouselSection = ({
  data: {
    backgroundColor,
    fontColor,
    heading,
    _rawBody,
    products,
    ctaText,
    ctaTarget
  }
}) => {
  // ---------------------------------------------------------------------------
  // context / ref / state
  const { isMobile, isDesktop, isLarge } = useBreakpoint();

  const [redraw, setRedraw] = useState(false);
  const [asCarousel, setAsCarousel] = useState(
    isMobile || products?.length > 4
  );
  const [spaceBetween, setSpaceBetween] = useState(8);
  const [slidesPerView, setSlidesPerView] = useState(2.5);

  // ---------------------------------------------------------------------------
  // variables

  useEffect(() => {
    setAsCarousel(isMobile || products?.length > 4);
  }, [isMobile, products]);

  useEffect(() => {
    setRedraw(true);

    if (isLarge) {
      setSpaceBetween(24);
      setSlidesPerView(5);
    } else if (isDesktop) {
      setSpaceBetween(24);
      setSlidesPerView(4.5);
    } else {
      setSpaceBetween(8);
      setSlidesPerView(2.5);
    }
  }, [isMobile, isDesktop, isLarge]);

  useEffect(() => {
    if (redraw) {
      setTimeout(() => {
        setRedraw(false);
      }, [10]);
    }
  }, [redraw]);

  // ---------------------------------------------------------------------------
  // render

  return (
    <Wrapper
      css={css`
        background: ${backgroundColor?.hex || `var(--color-white)`};
        color: ${fontColor?.hex || `var(--color-black)`};
      `}
    >
      <Grid
        _css={css`
          padding: 0 !important;
        `}
      >
        {heading && (
          <header
            css={css`
              grid-column: 1 / -1;
              padding: 0 1rem;
              text-align: center;

              ${MEDIA_QUERIES.desktop} {
                grid-column: 4 / span 6;
                padding: 0;
              }
            `}
          >
            <h2
              className="h2"
              css={css`
                text-transform: uppercase;
                text-align: center;
              `}
            >
              {heading}
            </h2>
          </header>
        )}

        {_rawBody && (
          <div
            css={css`
              grid-column: 1 / -1;
              margin-top: 2rem;
              padding: 0 1rem;
              text-align: center;

              ${MEDIA_QUERIES.desktop} {
                grid-column: 5 / span 4;
                padding: 0;
              }
            `}
          >
            <PortableText blocks={_rawBody} />
          </div>
        )}

        {(asCarousel && (
          <div
            css={css`
              margin-top: 2rem;
              padding: 1rem 0;
              grid-column: 1 / -1;
              text-align: center;

              ${MEDIA_QUERIES.desktop} {
                margin-top: 3.75rem;
              }
            `}
          >
            {!redraw && (
              <SwiperCarousel
                id={`carousel-swiper-desktop-${uuidv4()}`}
                css={css`
                  height: auto;
                `}
                options={{
                  autoplay: { delay: 4000 },
                  centeredSlides: true,
                  loopAdditionalSlides: 6,
                  spaceBetween,
                  slidesPerView,
                  draggable: true
                }}
                slides={products.map((product) => (
                  <div key={`product-card-${product.id}`}>
                    <ProductCard product={product} />
                  </div>
                ))}
              />
            )}

            {ctaTarget && ctaText && (
              <ButtonContainer>
                <Go
                  to={ctaTarget}
                  _css={css`
                    width: 100%;

                    ${MEDIA_QUERIES.desktop} {
                      width: auto;
                    }
                  `}
                >
                  <Button
                    text={ctaText}
                    _css={css`
                      width: max-content;
                      width: 100%;
                      margin: 0;

                      ${MEDIA_QUERIES.desktop} {
                        width: max-content;
                      }
                    `}
                  />
                </Go>
              </ButtonContainer>
            )}
          </div>
        )) || (
          <>
            {!redraw && (
              <ul
                css={css`
                  position: relative;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  gap: 0.5rem;
                  margin-top: 5rem;
                  padding: 1rem 0;
                  grid-column: 1 / -1;
                  text-align: center;

                  ${MEDIA_QUERIES.desktop} {
                    gap: 1.5rem;
                  }
                `}
              >
                {products.map((product) => (
                  <li key={`bullet-${product.id}-${uuidv4()}`}>
                    <ProductCard product={product} />
                  </li>
                ))}
              </ul>
            )}

            {ctaTarget && ctaText && (
              <ButtonContainer>
                <Go
                  to={ctaTarget}
                  _css={css`
                    width: 100%;

                    ${MEDIA_QUERIES.desktop} {
                      width: auto;
                    }
                  `}
                >
                  <Button
                    text={ctaText}
                    _css={css`
                      width: max-content;
                      width: 100%;
                      margin: 0;

                      ${MEDIA_QUERIES.desktop} {
                        width: max-content;
                      }
                    `}
                  />
                </Go>
              </ButtonContainer>
            )}
          </>
        )}
      </Grid>
    </Wrapper>
  );
};

export default ProductCarouselSection;

export const query = graphql`
  fragment ProductCarouselSectionFragment on SanityProductCarouselSection {
    _type

    backgroundColor {
      hex
      title
    }

    fontColor {
      hex
      title
    }

    heading

    _rawBody

    ctaText
    ctaTarget
    products {
      id
      _key
      name
      slug {
        current
      }
      collection {
        name
      }
      description
      primaryColour {
        hex
      }
      secondaryColour {
        hex
      }
      tertiaryColour {
        hex
      }
      isNew
      isVegan
      isDiscontinued
      gridImage {
        altText
        asset {
          gatsbyImageData(
            width: 800
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
        mobileImage {
          asset {
            gatsbyImageData(
              width: 720
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
      gallery {
        altText
        asset {
          gatsbyImageData(
            width: 800
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
        mobileImage {
          asset {
            gatsbyImageData(
              width: 720
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    }
  }
`;
